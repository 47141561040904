import React from "react"
import styled from "styled-components";
import { BannerContainer, Banner } from "../section/basic";
import { BiCodeAlt } from "react-icons/bi"
import { GoProject } from "react-icons/go";
import { DeviceSize } from "../../utils/deviceSize";

const KPIcontainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto;

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        flex-direction: column;
    }
`;

const KPI = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.light};
    margin-left: 50px;

    &:first-child { margin-left: 0 !important; }

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        margin: 0;
        margin-top: 50px;
        &:first-child { margin-top: 0 !important; }
    }
`;

const KPITitle = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 50px;
    font-weight: 600;
`;

const Number = styled.div`
    margin-left: 10px;
`;

const KPIDescription = styled.div`
    font-size: 15px;
    text-transform: uppercase;
    margin-top: 10px;
`;

export function AboutBanner(props) {
    return <BannerContainer>
        <Banner>
            <KPIcontainer>
                <KPI>
                    <KPITitle>
                        <BiCodeAlt size={60}/>
                        <Number>16</Number>
                    </KPITitle>
                    <KPIDescription>années d'expérience</KPIDescription>
                </KPI>
                <KPI>
                    <KPITitle>
                        <GoProject size={60}/>
                        <Number>2</Number>
                    </KPITitle>
                    <KPIDescription>projets terminés</KPIDescription>
                </KPI>
            </KPIcontainer>
        </Banner>
    </BannerContainer>
}

export default AboutBanner;