export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function hexToHSL(hex, lightness) {
    var h, s, l;

    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    r = r / 255;
    g = g / 255;
    b = b / 255;

    var cMax = Math.max(r, g, b);
    var cMin = Math.min(r, g, b);
    var delta = cMax - cMin;

    if( delta === 0 ) h = 0;
    else if( cMax === r ) h = ((g - b) / delta) % 6;
    else if( cMax === g ) h= (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h*=60;
    if( h < 0 ) h += 360;
    l = (cMax + cMin) / 2;
    if( delta === 0 )
        s = 0;
    else
        s = delta / (1 - Math.abs(2 * l - 1));
    s *= 100;
    l *= 100;
    
    return `hsl(${Math.round(h)},${Math.round(s)}%,${lightness}%)`;
}