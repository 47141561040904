import React from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { DeviceSize } from "../../utils/deviceSize";
import MarkerImg from "../../assets/images/marker.png";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.colors.colored};

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        height: 400px;
    }
`;

const Marker = styled.img
    .attrs({
        src: `${MarkerImg}`,
        alt: "Ma position",
    })`
    width: 32px;
    height: 45px;
`;

const LocationPin = ({ text }) => (
    <div className="pin">
        <Marker/>
        {/* <p className="pin-text">{text}</p> */}
    </div>
)

export function Map({location, zoomLevel}) {
    return <Container>
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyDIEdNmfG444xJmT3ih2n0yghUgWWsIMXM" }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
        >
            <LocationPin
                lat={location.lat}
                lng={location.lng}
                // text={location.address}
            />
        </GoogleMapReact>
    </Container>;
}

export default Map;