import React from "react";
import styled from "styled-components";
import { BannerContainer, Banner } from "../section/basic";
import SmeImage from "../../assets/images/sme.png"
import AssociationImage from "../../assets/images/association.png"
import ParticularImage from "../../assets/images/particular.png"
import { DeviceSize } from "../../utils/deviceSize";
import LazyLoad from 'react-lazyload';

const SmeImg = styled.img
    .attrs({
        src: `${SmeImage}`,
        alt: "PME",
    })`
    width: 150px;
    margin: auto;
`;

const AssociationImg = styled.img
    .attrs({
        src: `${AssociationImage}`,
        alt: "Associations",
    })`
    width: 150px;
    margin: auto;
`;

const ParticularImg = styled.img
    .attrs({
        src: `${ParticularImage}`,
        alt: "Particuliers",
    })`
    width: 150px;
    margin: auto;
`;

const Projectcontainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto;

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        flex-direction: column;

    }
`;

const Project = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.light};
    margin-left: 50px;
    align-items: center;

    &:first-child { margin-left: 0 !important; }

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        margin: 0;
        margin-top: 50px;
        &:first-child { margin-top: 0 !important; }
    }
`;

const ProjectDescription = styled.div`
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
`;

export function ProjectsBanner(props) {
    return <BannerContainer>
        <Banner>
            <Projectcontainer>
                <Project>
                    <LazyLoad height={150} once>
                        <SmeImg />
                    </LazyLoad>
                    <ProjectDescription>PME</ProjectDescription>
                </Project>
                <Project>
                    <LazyLoad height={150} once>
                        <AssociationImg />
                    </LazyLoad>
                    <ProjectDescription>Associations</ProjectDescription>
                </Project>
                <Project>
                    <LazyLoad height={150} once>
                        <ParticularImg />
                    </LazyLoad>
                    <ProjectDescription>Particuliers</ProjectDescription>
                </Project>
                
            </Projectcontainer>
        </Banner>
    </BannerContainer>;
}

export default ProjectsBanner;