import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Section, Container, Content, TitleContainer, Title, SubTitle, ContainerPadding, ContentTitle } from "../section/basic";
import { DeviceSize } from "../../utils/deviceSize";
import Map from "../map";
import { FaMapMarkerAlt, FaPhoneAlt, FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { useFormik } from "formik" 
import * as yup from "yup";
import { sendMail } from "../../services/contactServices";
import LazyLoad from 'react-lazyload';

const LeftSection = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: left;
`;

const MiddleSection = styled.div`
    display: flex;
    flex: 3;
    justify-content: left;
    padding: 0 20px;

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        padding: 0;
        margin-top: 20px;
    }

    & > .lazyload-wrapper {
        width: 100%;
    }
`;

const RightSection = styled.div`
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: left;

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        margin-top: 20px;
    }
`;

const Form = styled.form`
    width: 100%;
    display: flex; 
    flex-direction: column;
    text-align: left;
    color: ${props => props.theme.colors.light};
`;

const StyledButton = styled.button`
    background: ${props => props.theme.colors.colored};
    border: 0;
    padding: 1em; 
    text-transform: uppercase;
    color: ${props => props.theme.colors.light};
    margin-top: 20px;
    margin-left: 50%;
    
    &:hover, &:focus {
        background: ${props => props.theme.colors.black};
    }
    
`;

const CompanyInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr, 2fr;
    grid-gap: 10px;
    row-gap: 20px;
    grid-auto-rows: min-content;
    background-color: ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.light};
`;

const InfoIcon = styled.div`
    grid-column: 1;
`;

const Info = styled.div`
    grid-column: 2;
`;

const EmailLink = styled.a
    .attrs({
        href: "mailto:contact@david-maria.fr",
    })`
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.colored};
    }
`;

const PhoneLink = styled.a
    .attrs({
        href: "tel:+33623905279",
    })`
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.colored};
    }
    
    @media ${`(min-width: ${DeviceSize.tablet}px)`} {
        pointer-events: none;
        cursor: default;

        &:hover {
            color: ${props => props.theme.colors.light};
        }
    }
`;

const CompanyName = styled.h3`
    margin: 0;
    padding-bottom: 20px;
    text-align: left; 
    grid-column: 1 / 3;
`;

const location = {
    address: 'David MARIA',
    lat: 48.73945981183991,
    lng: 1.955101841204977,
}

const validationSchema = yup.object({
    name: yup.string().required("Le nom est obligatoire"),
    email: yup.string().email("L'adresse email n'est pas valide").required("L'adresse email est obligatoire"),
    message: yup.string().required("Le message est obligatoire"),
});

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputIcon = styled.div`
    border: 1px solid ${props => props.theme.colors.light};   
    background: ${props => props.theme.colors.black};
    padding: 10px;
`;

const Input = styled.input`
    border: 1px solid ${props => props.theme.colors.light};   
    /* border: none; */
    flex: 1;
    padding: 10px;

    &:focus {
        outline: none !important;
        border: none;
    }
`;

const Textarea = styled.textarea`
    border: none;
    flex: 1;
    padding: 10px;

    &:focus {
        outline: none !important;
        border: none;
    }
`; 

const InputMandatory = styled.div`
    padding: 10px;
`;

export const FieldContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &:first-child {
        margin-top: 0 !important;
    }
`;

export const FieldError = styled.span`
    color: ${props => props.theme.colors.light};
    font-size: 11px;
    min-height: 20px;
`;

export const FormSuccess = styled.span`
    color: ${props => props.theme.colors.success};
    font-size: 12px;
    min-height: 20px;
`;

export function Contact(props) {
    const themeContext = useContext(ThemeContext);
    
    const [ success, setSuccess] = useState(null);

    const onSubmit = async (values) => {
        const { ...data } = values;

        const callback = (message) => {
            setSuccess(message);
        };

        sendMail(data, callback);
    };

    const formik = useFormik({ 
        initialValues: { name: "", email: "", message: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return <Section id="contact" bgcolor={themeContext.colors.dark}>
        <Container>
            <ContainerPadding>
                <TitleContainer>
                    <Title color={themeContext.colors.light}>Contact</Title>
                    <SubTitle color={"#EEEEEE"}>Une question, une idée, un projet ?</SubTitle>
                </TitleContainer>
                <Content>
                    <LeftSection>
                        <CompanyInfo> 
                            <CompanyName>
                                <ContentTitle>
                                    David MARIA
                                </ContentTitle>
                            </CompanyName>
                            <InfoIcon>
                                <FaMapMarkerAlt size={25} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                1 allée du Puits des Granges,<br/>78320 Le Mesnil Saint Denis
                            </Info>
                            <InfoIcon>
                                <FaPhoneAlt size={25} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                <PhoneLink>+33 6 23 90 52 79</PhoneLink>
                            </Info>
                            <InfoIcon>
                                <MdEmail size={25} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                <EmailLink>contact@david-maria.fr</EmailLink>
                            </Info>
                            <InfoIcon>
                                <AiOutlineFieldNumber size={25} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                SIREN: 753 499 623
                            </Info>
                        </CompanyInfo>
                    </LeftSection>
                    <MiddleSection>
                        <LazyLoad height={400}>
                            <Map location={location} zoomLevel={8} />          
                        </LazyLoad>
                    </MiddleSection>
                    <RightSection>
                        <Form onSubmit={formik.handleSubmit}>
                            <FieldContainer>
                                <InputContainer>
                                    <InputIcon>
                                        <FaUser size={20} color={themeContext.colors.light}/>
                                    </InputIcon>
                                    <Input 
                                        id="name" 
                                        type="text" 
                                        name="name"
                                        placeholder="Nom / Prénom"
                                        aria-label="Nom / Prénom"
                                        value={formik.values.name} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                    <InputMandatory>*</InputMandatory>
                                </InputContainer>
                                <FieldError>{formik.touched.name && formik.errors.name ? formik.errors.name : ""}</FieldError>
                            </FieldContainer>
                            <FieldContainer>
                                <InputContainer>
                                    <InputIcon>
                                        <MdEmail size={20} color={themeContext.colors.light}/>
                                    </InputIcon>
                                    <Input 
                                        id="email" 
                                        type="email" 
                                        name="email"
                                        placeholder="Email"
                                        aria-label="Email"
                                        value={formik.values.email} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                        <InputMandatory>*</InputMandatory>
                                    </InputContainer>
                                <FieldError>{formik.touched.email && formik.errors.email ? formik.errors.email : ""}</FieldError>
                            </FieldContainer>
                            <FieldContainer>
                                <InputContainer>
                                    <InputIcon>
                                        <FaPhoneAlt size={20} color={themeContext.colors.light}/>
                                    </InputIcon>
                                    <Input 
                                        id="phone" 
                                        type="text" 
                                        name="phone"
                                        placeholder="Téléphone"
                                        aria-label="Téléphone" />
                                    <InputMandatory>&nbsp;</InputMandatory>
                                </InputContainer>
                                <FieldError></FieldError>
                            </FieldContainer>
                            <FieldContainer>
                                <InputContainer>
                                    <Textarea 
                                        id="message" 
                                        name="message" 
                                        placeholder="Message"
                                        aria-label="Message"
                                        rows="5"
                                        value={formik.values.message} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                    <InputMandatory>*</InputMandatory>
                                </InputContainer>
                                <FieldError>{formik.touched.message && formik.errors.message ? formik.errors.message : ""}</FieldError>
                            </FieldContainer>
                            <FormSuccess>{success ? success : ""}</FormSuccess>
                            <StyledButton type="submit">Envoyer</StyledButton>
                        </Form>
                    </RightSection>
                </Content>
            </ContainerPadding>
        </Container>
    </Section>;
}

export default Contact;