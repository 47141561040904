import React, { useState } from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { AiFillHome } from "react-icons/ai"
import { MenuToggle } from "./menuToggle";
import { hexToRGB } from "../../utils/colorHelper";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 20;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  
  background-color: ${props => props.theme.colors.dark};
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: ${props => props.theme.sizes.navBarHeight};
  left: 0;
`;

const LinkItem = styled.li`
  width: 100%;
  height: 50px;
  padding: 0 1.1em;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;
  text-align: start;
  align-items: center;

  > a {
    width: 100%;
    position: relative;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 4px 0;
    transition: 0.5s;
  }

  &:hover {
    background-color: ${props => hexToRGB(props.theme.colors.light, 0.5)};
  }
  
  &:active {
    background-color: ${props => hexToRGB(props.theme.colors.light, 0.5)};
  }
`;

export function MobileNavLinks(props) {
    const [isOpen, setOpen] = useState(false);

    return <NavLinksContainer>
        <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
        {isOpen && (
            <LinksWrapper >
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#home" aria-label="accueil" >
                        <AiFillHome size={30}/>
                    </HashLink>
                </LinkItem>
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#services" aria-label="services" >
                        services
                    </HashLink>
                </LinkItem>
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#skills" aria-label="compétences" >
                        compétences
                    </HashLink>
                </LinkItem>
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#projects" aria-label="projets" >
                        projets
                    </HashLink>
                </LinkItem>
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#about" aria-label="à propos" >
                        à propos
                    </HashLink>
                </LinkItem>
                <LinkItem>
                    <HashLink 
                        onClick={() => setOpen(!isOpen)}
                        smooth to="/#contact" aria-label="contact" >
                        contact
                    </HashLink>
                </LinkItem>
            </LinksWrapper>
        )}
    </NavLinksContainer>;
}