import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { AiFillHome } from "react-icons/ai"

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`;

const LinkItem = styled.li`
    height: 100%;
    padding: 0 1.1em;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;

    > a {
        position: relative;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        padding: 4px 0;
        transition: 0.5s;
    }

    > a::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        top: 100%;
        left: 0;
        background: ${props => props.theme.colors.white};
        transition: transform 0.5s;
        transform: scaleX(0);
        transform-origin: center;
    }

    > a:hover::after {
        transform: scaleX(1);
        transform-origin: center;
    }
`;

export function NavLinks(props) {
    return <NavLinksContainer>
        <LinksWrapper>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#home" aria-label="accueil" >
                    <AiFillHome size={30}/>
                </HashLink>
            </LinkItem>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#services" aria-label="services" >
                    services
                </HashLink>
            </LinkItem>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#skills" aria-label="compétences" >
                    compétences
                </HashLink>
            </LinkItem>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#projects" aria-label="projets" >
                    projets
                </HashLink>
            </LinkItem>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#about" aria-label="à propos" >
                    à propos
                </HashLink>
            </LinkItem>
            <LinkItem className={props.className}>
                <HashLink 
                    smooth to="/#contact" aria-label="contact" >
                    contact
                </HashLink>
            </LinkItem>
      </LinksWrapper>
    </NavLinksContainer>
}