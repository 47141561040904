import React from "react";
import { ThemeProvider } from "styled-components";
import BackgroundImage from "./assets/images/background.jpg"
import { DeviceSize } from "./utils/deviceSize";
import { useMediaQuery } from "react-responsive";


const theme = (isMobile) => {
  return {
    // IMAGES
    images: {
      backgroundImage: `url(${BackgroundImage})`,
    },
    // COLORS
    colors: {
      white: "#FFFFFF",
      light: "#FFFFFF",
      colored: "#DB2B39",
      dark: "#2E3238",
      black: "#000000",
      success: "#28a828",
    },
    // FONTS
    fonts: ["Montserrat, sans-serif, Roboto"],
    fontSizes: {
        title: isMobile ? "2.5em" : "5em",
        subTitle:  isMobile ? "1.2em" : "2em",
        contentTitle: isMobile ? "1.1em" : "1.5em",
        contentSubtitle: isMobile ? "1em" : "1.2em",
        contentText: isMobile ? "0.9em" : "1em",
        contentSubtext: isMobile ? "0.7em" : "0.9em",
        footerContent: isMobile ? "0.8em" : "0.8em",
    },
    letterSpacings: {
      title: isMobile ? "-1px" : "-5px",
    },
    // SIZES
    sizes: {
      logoHeight: isMobile ? "32px" : "64px",
      logoWidth: isMobile ? "32px" : "64px",
      navBarHeight: isMobile ? "70px" : "100px",
      navBarHeightInteger: isMobile ? 50 : 100,
      sectionContentDesktopWidth: "1200px",
      sectionContentLaptopWidth: "950px",
      sectionContentTabletWidth: "750px",
    },
    // MARGINS  
    margins: {
      sectionTitleMarginTop: isMobile ? "20px" : "30px",
      sectionContentMarginTop: isMobile ? "30px" : "70px",
      sectionContentMarginBottom: isMobile ? "30px" : "70px",
      footerContentMarginTop: isMobile ? "10px" : "30px",
      footerContentMarginBottom: isMobile ? "10px" : "30px",
    },
  }
};

export function Theme({ children }) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  return <ThemeProvider theme={theme(isMobile)}>{children}</ThemeProvider>;
}

export default Theme;

