import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Footer, Container, ContainerPadding, ContentHeader, Content, ContentTitle, ContentFooter, Version } from "../footer";
import { DeviceSize } from "../../utils/deviceSize";
import Logo from "../logo";
import { FaFacebookSquare, FaLinkedin, FaTwitter, FaGithub, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillCloud }from "react-icons/ai";
import { HashLink } from "react-router-hash-link";

const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;

    & > a {
        margin-left: 10px;
        
        &:first-child { margin-left: 0 !important; }
    }
`;

const SocialLink = styled.a
    .attrs(props => ({
        src: props.url || "",
    }))`        
`;

const SiteMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    flex: 1;

    & > a {
        text-decoration: underline;
        margin-top: 7px;
        
        &:first-child { margin-top: 0 !important; }
    }
`;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        margin-top: 40px;
    }
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        margin-top: 40px;
        align-items: flex-start;
    }
`;

const ContactInfo = styled.div`
    display: grid;
    grid-template-columns: 25px auto;
    grid-gap: 5px;
    row-gap: 10px;
    grid-auto-rows: min-content;
`;

const InfoIcon = styled.div`
    grid-column: 1;
`;

const Info = styled.div`
    grid-column: 2;
`;

const EmailLink = styled.a
    .attrs({
        href: "mailto:contact@david-maria.fr",
    })`
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.colored};
    }
`;

const PhoneLink = styled.a
    .attrs({
        href: "tel:+33623905279",
    })`
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.colored};
    }
    
    @media ${`(min-width: ${DeviceSize.tablet}px)`} {
        pointer-events: none;
        cursor: default;
    }
`;

const WebLink = styled.a
    .attrs({
        href: "https://david-maria.fr",
    })`
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.colored};
    }
`;

const LegalNotice = styled.button`
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.light};
    padding: 10px;
`;

const Copyright = styled.div`
    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        margin-top: 30px;
    }
`;

const WebLinkFooter = styled.a
    .attrs({
        href: "https://david-maria.fr",
    })`
    text-decoration: none;
    color: ${props => props.theme.colors.colored};

    &:hover {
        color: ${props => props.theme.colors.light};
    }
`;

export function Infos(props) {
    const themeContext = useContext(ThemeContext);

    return <Footer>
        <Container>
            <ContainerPadding>
                <ContentHeader>
                    <Logo/>
                    <SocialContainer>
                        {/* <SocialLink url="">
                            <FaLinkedin size={40}/>
                        </SocialLink>
                        <SocialLink url="">
                            <FaFacebookSquare size={40}/>
                        </SocialLink>
                        <SocialLink url="">
                            <FaTwitter size={40}/>
                        </SocialLink>
                        <SocialLink url="">
                            <FaGithub size={40}/>
                        </SocialLink> */}
                    </SocialContainer>
                </ContentHeader>
                <Content>
                    <SiteMapContainer>
                        <HashLink smooth to="/#home" aria-label="accueil">
                            Accueil
                        </HashLink>
                        <HashLink smooth to="/#services" aria-label="services">
                            Services
                        </HashLink>
                        <HashLink smooth to="/#skills" aria-label="compétences">
                            Compétences
                        </HashLink>
                        <HashLink smooth to="/#projects" aria-label="projets">
                            Projets
                        </HashLink>
                        <HashLink smooth to="/#about" aria-label="à propos">
                            à propos
                        </HashLink>
                        <HashLink smooth to="/#contact" aria-label="contact">
                            Contact
                        </HashLink>
                    </SiteMapContainer>
                    <SummaryContainer>
                        <ContentTitle>
                            développeur informatique indépendant
                        </ContentTitle>
                        Développeur web et applicatif (front & back) disponible pour tout type de projet.
                    </SummaryContainer>
                    <ContactContainer>
                        <ContactInfo>
                            <InfoIcon>
                                <FaMapMarkerAlt size={20} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                1 allée du Puits des Granges,<br/>78320 LE MESNIL SAINT DENIS
                            </Info>
                            <InfoIcon>
                                <FaPhoneAlt size={20} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                <PhoneLink>+33 6 23 90 52 79</PhoneLink>
                            </Info>
                            <InfoIcon>
                                <MdEmail size={20} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                <EmailLink>contact@david-maria.fr</EmailLink>
                            </Info>
                            <InfoIcon>
                                <AiFillCloud size={20} color={themeContext.colors.colored}/>
                            </InfoIcon>
                            <Info>
                                <WebLink>www.david-maria.fr</WebLink>
                            </Info>
                        </ContactInfo>
                    </ContactContainer>
                </Content>
                <ContentFooter>
                    {/* <LegalNotice>MENTIONS LEGALES</LegalNotice> */}
                    <Copyright>&copy; Copyright 2022 <WebLinkFooter>DAVID MARIA <Version>1.0.0</Version></WebLinkFooter></Copyright>
                </ContentFooter>
            </ContainerPadding>
        </Container>
    </Footer>
}

export default Infos;