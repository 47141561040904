import React, { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { ThemeContext } from "styled-components";
import { Logo } from "../logo";
import { DeviceSize } from "../../utils/deviceSize";
import { MobileNavLinks } from "./mobileNavLinks";
import { NavLinks } from "./navLinks";
import { hexToRGB } from "../../utils/colorHelper";

const Container = styled.div`
  width: 100%;
  height: ${props => props.theme.sizes.navBarHeight};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${props => hexToRGB(props.theme.colors.dark, 0.5)};
  color: ${props => props.theme.colors.white};

  &.sticky {
    background-color: ${props => props.theme.colors.dark};
    box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  }
`;

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
`;

const LeftSection = styled.div`
  display: flex;
`;

const MiddleSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
`;

const RightSection = styled.div`
  display: flex;
`;

export function NavBar(props) {
  const themeContext = useContext(ThemeContext);

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [isSticky, setIsSticky] = useState(false);

  const changeSticky = () => {
    const offset = window.scrollY;
    const limit = themeContext.sizes.navBarHeightInteger;
    if(offset >= limit) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }

  window.addEventListener("scroll", changeSticky);

  return <Container className={isSticky ? "sticky" : ""}>
    <NavbarContainer>
      <LeftSection>
          <Logo className={isSticky ? "sticky" : ""}/>
      </LeftSection>
      <MiddleSection>
          {!isMobile && (
              <NavLinks className={isSticky ? "sticky" : ""}/>
          )}
      </MiddleSection>
      <RightSection>
          {isMobile && (
              <MobileNavLinks/>
          )}
      </RightSection>
    </NavbarContainer>
  </Container>

}