import "./App.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavBar } from "./components/navbar";
import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';
import MainPage from "./pages";
import NotFoundPage from "./pages/404";
import Theme from "./theme";
import Timer from "./components/timer";

import { getData } from "./services/globalServices";

const AppContainer = styled.div`
    width: 100%;
    font-family: ${props => props.theme.fonts};
    background-image: ${props => props.theme.images.backgroundImage};
    background-size: cover;
    background-attachment: fixed;
    font-size: ${props => props.theme.fontSizes.contentText};
    // added for perf
    font-display: swap;
`;

function App() {
  const [data, setData] = useState([]);

  useEffect(() =>{
    const callback = (data) => {
      setData(data);
    };
    getData(callback);
  }, [setData]);

  return (
    <Theme>
      <AppContainer>
        { 
          data && data.underConstruction 
          ? <Timer openDate={data.openDate}/>
          : <Router>
              <NavBar/>
              <Routes>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="/404" element={<NotFoundPage />} />
              </Routes>
            </Router>
        }
      </AppContainer>
    </Theme>
  );
}

export default App;
