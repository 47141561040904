import React, { useState } from "react";
import styled from "styled-components";
import Filters from "./filters";
import { Item } from "./item";
import { motion, AnimatePresence } from "framer-motion";

const Container = styled.div`
    width: 100%;
`;

const Grid = styled(motion.div).attrs({
    layout: true,
})`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 380px));
`;

export function Gallery({items, toggleModal}) {
    const [filteredItems, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState("all");

    return (
        <Container>
            <Filters 
                items={items} 
                activeFilter={activeFilter} 
                setFilters={setFilters}
                setActiveFilter={setActiveFilter}
            />
            
            <Grid>
                <AnimatePresence>
                    {
                        filteredItems.map(item => (
                            <Item key={item.id} item={item} toggleModal={toggleModal} />
                        ))
                    }
                </AnimatePresence>
            </Grid>
        </Container>
    );
}

export default Gallery;