import React, { useEffect, useState } from "react";
import { Section, Container, Content, Title, SubTitle, TitleContainer, ContainerPadding } from "../section/basic";
import { Gallery } from "../gallery";

import { getData } from "../../services/projectServices";
import Modal from "../modal";
import { ProjectDetail } from "./projectDetail";
import { AnimatePresence } from "framer-motion";

export function Projects(props) {

    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [project, setProject] = useState(null);

    useEffect(() => {
        const callback = (data) => {
            setData(data);
        }
        getData(callback);
    }, [setData]);

    const toggleModal = (item) => {
        if(!isOpen) {
            setProject(item);
        }
        setIsOpen(!isOpen);
    }

    return <Section id="projects">
        <Container>
            <ContainerPadding>
                <TitleContainer>
                    <Title>Mes projets</Title>
                    <SubTitle>Voici une liste des projets que j'ai réalisés.</SubTitle>
                </TitleContainer>
                <Content>
                    <Gallery items={data} toggleModal={toggleModal} />
                </Content>
            </ContainerPadding>
            <AnimatePresence>
                { isOpen && (
                        <Modal isOpen={isOpen} onClose={toggleModal}>
                            <ProjectDetail project={project}/>
                        </Modal>
                    )
                }
            </AnimatePresence>
        </Container>
    </Section>;
}

export default Projects;