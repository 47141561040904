import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { DeviceSize } from "../../utils/deviceSize";
import { FaCalendarAlt } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { BsPatchCheckFill } from "react-icons/bs";
import { ContentSubtextNegativeHighlighted, ContentSubtitle, ContentTitle } from "../section/basic";

const ClickedProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`;

const ClickedProjectImages = styled.div`
    display: flex;
    flex-direction: row;
    
    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        flex-direction: column;
    }
`;

const CurrentProjectImage = styled.img
    .attrs(props => ({
        src: props.url || "",
        alt: props.name || "",
    }))`
    width: 100%;
    object-fit: contain;
    margin-left: 10px;

    &:first-child {
        margin-left: 0;
    }
    
    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        margin-top: 10px;
        margin-left: 0;

        &:first-child {
            margin-top: 0;
        }
    }
`;

const CurrentProjectDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        flex-direction: column;
    }
`;

const CurrentProjectDescritpion = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 5px;
    margin-top: 20px;
`;

const Line = styled.hr`
    width: 60%;
    margin-left: 0;
    border: 1px solid ${props => props.theme.colors.dark};
    margin-bottom: 30px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 25px auto;
    grid-gap: 5px;
    row-gap: 10px;
    grid-auto-rows: min-content;
`;

const Icon = styled.div`
    grid-column: 1;
    color: ${props => props.theme.colors.colored};
`;

const Info = styled.div`
    grid-column: 2;
`;

const Technologies = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    /* grid-auto-columns: min-content; */
    grid-gap: 10px;
`;

const Technology = styled.div`
    background-color: ${props => props.theme.colors.colored};
    padding: 10px 9px;
`;

const Link = styled.a`
    margin-top: 10px;
    width: 150px;
    text-align: center;
    margin-left: auto;
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.colored};
    color: ${props => props.theme.colors.colored};
    padding: 10px;

    &:hover {
        background-color: ${props => props.theme.colors.colored};
        color: ${props => props.theme.colors.light};
    }
`;

const VerticalMarginer = styled.div`
    height: 20px;
`;

export function ProjectDetail({project}) {
    const themeContext = useContext(ThemeContext);
    
    if(project == null) {
        return null;
    }

    return <ClickedProjectContainer>
        <ContentTitle>{project.name}</ContentTitle>
        <Line/>
        <ClickedProjectImages>
            <CurrentProjectImage url={project.image} name={project.name + " image 1"} />
            <CurrentProjectImage url={project.image2} name={project.name + " image 2"} />
        </ClickedProjectImages>
        <CurrentProjectDescriptionContainer>
            <CurrentProjectDescritpion>
                <ContentSubtitle>{project.type}</ContentSubtitle>
                <VerticalMarginer/>
                {project.description}
                <Line/>
                <Grid>
                    {
                        project.tasks.map(task => (
                            <>
                                <Icon><BsPatchCheckFill size={20} color={themeContext.colors.colored}/></Icon>
                                <Info>
                                    {task}
                                </Info>
                            </>
                        ))
                    }
                </Grid>
            </CurrentProjectDescritpion>
            <CurrentProjectDescritpion>
                <ContentSubtitle>infos</ContentSubtitle>
                <Line/>
                <Grid>
                    <Icon><FaCalendarAlt size={20} color={themeContext.colors.colored}/></Icon>
                    <Info>année : {project.year}</Info>
                    <Icon><MdCategory size={20} color={themeContext.colors.colored}/></Icon>
                    <Info>catégorie : {project.sub_category}</Info>
                </Grid>
                <Link href={project.link}>Visitez le site</Link>
                <ContentSubtitle>technologies</ContentSubtitle>
                <Line/>
                <Technologies>
                    {
                        project.technologies.map(technology => (
                            <Technology>
                                <ContentSubtextNegativeHighlighted>
                                    {technology}
                                </ContentSubtextNegativeHighlighted>
                            </Technology>
                        ))
                    }
                </Technologies>
            </CurrentProjectDescritpion>
        </CurrentProjectDescriptionContainer>
    </ClickedProjectContainer>
}