import React from "react";

const NotFoundPage = () => {
    return (
        <div>
            <h2>404 Not Found!</h2>
        </div>
    );
};

export default NotFoundPage;