import styled from "styled-components";
import { DeviceSize } from "../../../utils/deviceSize";

// HOME SECTION
export const Section = styled.section`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: ${props => props.theme.images.backgroundImage};
    background-size: cover;
    background-color: ${({ bgcolor }) => bgcolor || (props => props.theme.colors.dark)};
    color: ${props => props.theme.colors.light}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: ${props => props.theme.sizes.navBarHeight};

    @media ${`(min-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentDesktopWidth};
    }

    @media ${`(min-width: ${DeviceSize.tablet}px) and (max-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentLaptopWidth};
    }

    @media ${`(min-width: ${DeviceSize.mobile}px) and (max-width: ${DeviceSize.tablet}px)`} {
        width: ${props => props.theme.sizes.sectionContentTabletWidth};
    }
`;

export const ContainerPadding = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

export const TitleContainer = styled.div`
`;

export const SiteName = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    color: ${props => props.theme.colors.light};

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        font-size: 1em;
    }
`;

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 3em;
    color: ${props => props.theme.colors.light};

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        font-size: 2em;
    }
`;

export const Marginer = styled.div`
  height: 200px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: ${props => props.theme.margins.sectionContentMarginTop};
    margin-bottom: ${props => props.theme.margins.sectionContentMarginBottom};

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        flex-direction: column;
    }
`;