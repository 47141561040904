import styled from "styled-components";
import { DeviceSize } from "../../utils/deviceSize";

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.footerContent};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    @media ${`(min-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentDesktopWidth};
    }

    @media ${`(min-width: ${DeviceSize.tablet}px) and (max-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentLaptopWidth};
    }

    @media ${`(min-width: ${DeviceSize.mobile}px) and (max-width: ${DeviceSize.tablet}px)`} {
        width: ${props => props.theme.sizes.sectionContentTabletWidth};
    }
`;

export const ContainerPadding = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: ${props => props.theme.margins.footerContentMarginTop};
    margin-bottom: ${props => props.theme.margins.footerContentMarginBottom};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: ${props => props.theme.margins.footerContentMarginTop};
    margin-bottom: ${props => props.theme.margins.footerContentMarginBottom};
    background-color: ${props => props.theme.colors.black};

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        flex-direction: column;
    }
`;

export const ContentTitle = styled.h1`
    text-transform: uppercase;
`;

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: ${props => props.theme.margins.footerContentMarginTop};
    margin-bottom: ${props => props.theme.margins.footerContentMarginBottom};

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        flex-direction: column;
    }
`;

export const Version = styled.sup`
    font-size: 8px;
`;

