import React from "react";
import styled from "styled-components";
import { Section, Container, Content, TitleContainer, Title, SubTitle, ContainerPadding } from "../section/basic";
import ResponsiveImg from "../../assets/images/photo.jpg"
import { DeviceSize } from "../../utils/deviceSize";
import LazyLoad from 'react-lazyload';

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: left;
`;

const MiddleSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  margin: 0 20px;

  @media ${`(max-width: ${DeviceSize.tablet}px)`} {
    text-align: left;
    margin: 20px 0;
}
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: left;
`;

const Image = styled.img
    .attrs({
        src: `${ResponsiveImg}`,
        alt: "David MARIA",
    })`
    width: 100%;
    max-width: 200px;
    border-radius: 50%;
    margin: auto;
`;

const Paragraph = styled.p`
    text-align: justify;

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        text-align: left;
    }
`;

export function About(props) {
    return <Section id="about">
        <Container>
            <ContainerPadding>
                <TitleContainer>
                    <Title>à propos</Title>
                    <SubTitle>Ma passion c'est le développement!</SubTitle>
                </TitleContainer>
                <Content>
                    <LeftSection>
                        <Paragraph>
                            Je suis passionné de développement et de nouvelles technologies depuis plus de 20 ans.
                        </Paragraph>
                        <Paragraph>
                            Avec plus de 15 ans d'expérience dans le milieu professionnel en tant que développeur &#171; full stack &#187;, je décide en 2022 de devenir <b>développeur web et applicatif indépendant</b>. 
                        </Paragraph>
                        <Paragraph>
                            Du projet le plus simple au plus complexe, je vous accompagne vers la réussite.
                        </Paragraph>
                    </LeftSection>
                    <MiddleSection>
                        <LazyLoad height={200} once>
                            <Image/>
                        </LazyLoad>
                    </MiddleSection>
                    <RightSection>
                        <Paragraph>
                            Ayant principalement travaillé pour des PME en pleine croissance, j'ai acquis une grande polyvalence dans les domaines suivants : 
                        </Paragraph>
                        <Paragraph style={{marginLeft: "30px"}}>
                            - récupération des besoins client,
                            <br/> 
                            - analyse fonctionnelle,
                            <br/> 
                            - analyse technique,
                            <br/> 
                            - développement,
                            <br/> 
                            - tests,
                            <br/> 
                            - mise en production.
                        </Paragraph>
                    </RightSection>
                </Content>
            </ContainerPadding>
        </Container>
    </Section>;
}

export default About;