import React, { Component } from "react";
import styled from "styled-components";
import { Container, ContainerPadding, Content, Section, SiteName, Title, TitleContainer } from "../section/home";
import { hexToRGB } from "../../utils/colorHelper";
import { DeviceSize } from "../../utils/deviceSize";

const Counter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: ${props => hexToRGB(props.theme.colors.dark, 0.5)};
  text-align: center;
  margin: auto;

  @media ${`(max-width: ${DeviceSize.mobile}px)`} {
      flex-direction: column;
  }
`;

const CounterBox = styled.div`
  min-width: 150px;
  padding: 20px 0;
  border-top: none;
  border-left: solid 1px rgba(255, 255, 255, 0.2);
  
  &:first-child { border-left: none !important; }

  @media ${`(max-width: ${DeviceSize.mobile}px)`} {
      border-left: none;
      border-top: solid 1px rgba(255, 255, 255, 0.2);

      &:first-child { border-top: none !important; }
  }
`;

const CountNumber = styled.div`
  font-size: 50px;
  font-weight: 600;
`;

const CountDescription = styled.div`
  font-size: 18px;
`;

class Timer extends Component {
  constructor(props) {
    super(props);

    this.openDate = props.openDate;
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: ""
    };
    this.x = null;
    this.deadline = null;
  }

  count() {
    var now = new Date().getTime();
    var t = this.deadline - now;
    var dd = Math.floor(t / (1000 * 60 * 60 * 24));
    var hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var ss = Math.floor((t % (1000 * 60)) / 1000);

    var days = dd < 10 ? "0" + dd : dd;
    var hours = hh < 10 ? "0" + hh : hh;
    var minutes = mm < 10 ? "0" + mm : mm;
    var seconds = ss < 10 ? "0" + ss : ss;

    this.setState({ days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
      });
    }
  }

  componentDidMount() {
    // year, month, day, hour, minute, second, ms
    this.deadline = new Date(this.openDate).getTime();

    this.x = setInterval(this.count, 1000);
  }

  render() {
    const { days, seconds, hours, minutes } = this.state;
    return (
      <Section>
        <Container>
          <ContainerPadding>
            <TitleContainer>
              <SiteName>david-maria.fr</SiteName>
              <Title>Le site arrive bientôt</Title>
            </TitleContainer>
            <Content>
              <Counter>
                <CounterBox>
                  <CountNumber>{days}</CountNumber>
                  <CountDescription>Jour(s)</CountDescription>
                </CounterBox>
                <CounterBox>
                  <CountNumber>{hours}</CountNumber>
                  <CountDescription>Heure(s)</CountDescription>
                </CounterBox>
                <CounterBox>
                  <CountNumber>{minutes}</CountNumber>
                  <CountDescription>Minute(s)</CountDescription>
                </CounterBox>
                <CounterBox>
                  <CountNumber>{seconds}</CountNumber>
                  <CountDescription>Seconde(s)</CountDescription>
                </CounterBox>
              </Counter>
            </Content>
          </ContainerPadding>
        </Container>
      </Section>
    );
  }
}

export default Timer;
