import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { BannerContainer, Banner } from "../section/basic";
import ResponsiveImg from "../../assets/images/responsive.png"
import { motion, useAnimation } from "framer-motion";
import useOnScreen from "../../utils/useOnScreen";
import LazyLoad from 'react-lazyload';

const Image = styled(motion.img)
    .attrs({
        src: `${ResponsiveImg}`,
        initial: "hidden", 
        variants: {
            visible: { x: 0 },
            hidden: { x: "-100vw" }
        },
        transition: { type: "spring", stiffness: 100 }
    })`
    max-width: 100%;
    margin: auto;
`;

const TextContainer = styled(motion.h3)
    .attrs({
        initial: "hidden", 
        variants: {
            visible: { x: "0" },
            hidden: { x: "100vw" }
        },
        transition: { type: "spring", stiffness: 100 }
    })`
    color: #fff;
    text-transform: uppercase;
`;

export function ServicesBanner(props) {
    const ref = useRef(null);
    const controls = useAnimation();

    useEffect(() => {}, []);

    const isVisible = useOnScreen(ref);
    if(isVisible) {
        console.log(isVisible);
        setTimeout(() => {
            controls.start("visible");
        }, 300);
    }

    return <BannerContainer>
        <Banner ref={ref}>
            <LazyLoad height="100%" once>
                <Image animate={controls}/>
            </LazyLoad>
            <TextContainer animate={controls}>
                développement responsive
            </TextContainer>
        </Banner>
    </BannerContainer>;
}

export default ServicesBanner;