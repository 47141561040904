import React from "react";
import styled from "styled-components";
import { BannerContainer, Banner } from "../section/basic";
import SkillsImg from "../../assets/images/skills.png"
import LazyLoad from 'react-lazyload';

const Image = styled.img
    .attrs({
        src: `${SkillsImg}`,
        alt: "Compétences",
    })`
    max-height: 300px;
    max-width: 100%;
    margin: auto;
`;

export function SkillsBanner(props) {
    return <BannerContainer>
        <Banner>
            <LazyLoad height={300} once>
                <Image/>
            </LazyLoad>
        </Banner>
    </BannerContainer>;
}

export default SkillsBanner;