import React from "react";
import styled, { keyframes } from "styled-components";
import { HashLink } from "react-router-hash-link";
import { Section, Container, ContainerPadding, TitleContainer, SiteName, Title, Marginer, Content  } from "../section/home";

const Line = styled.hr`
  margin-right: 50%;
  border: 5px solid ${props => props.theme.colors.colored};
`;

const SlideButton = styled.div`
  width: 45px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.light};
  border-radius: 50px;
`;

const SlideAnimation = keyframes`
  0% { opacity:0; transform: translateY(-30px); }		
  20% { opacity:1; transform: translateY(-15px); }	
  80% { opacity:1; transform: translateY(15px); }	
  100% { opacity:0; transform: translateY(30px); }	
`

const SlideButtonArrowMove = styled.div`
  position: absolute;
  -webkit-animation: ${SlideAnimation} 4s linear infinite; 
  animation: ${SlideAnimation} 4s linear infinite;

  &.delay1 {
    -webkit-animation-delay: 1s; 
    animation-delay: 1s;
  }

  &.delay2 {
    -webkit-animation-delay: 2s; 
    animation-delay: 2s;
  }

  &.delay3 {
    -webkit-animation-delay: 3s; 
    animation-delay: 3s;
  }
`;

const SlideButtonArrow = styled.div`
  width: 10px;
  height: 10px;
  border: 4px solid;
  border-color: ${props => props.theme.colors.colored} transparent transparent ${props => props.theme.colors.colored};
  transform: rotate(-135deg);
`;

export function Home(props) {
  return <Section id="home">
    <Container>
      <ContainerPadding>
        <TitleContainer>
          <SiteName>David MARIA</SiteName>
          <Title>Développeur informatique<br/>indépendant</Title>
          <Line/>
        </TitleContainer>
        <Marginer/>
        <Content>
          <HashLink aria-label="services"
              smooth to="/#services" >
              <SlideButton>
                <SlideButtonArrowMove>
                  <SlideButtonArrow></SlideButtonArrow>
                </SlideButtonArrowMove>
                <SlideButtonArrowMove className="delay1">
                  <SlideButtonArrow></SlideButtonArrow>
                </SlideButtonArrowMove>
                <SlideButtonArrowMove className="delay2">
                  <SlideButtonArrow></SlideButtonArrow>
                </SlideButtonArrowMove>
                <SlideButtonArrowMove className="delay3">
                  <SlideButtonArrow></SlideButtonArrow>
                </SlideButtonArrowMove>
              </SlideButton>
              {/* <IoIosArrowDropdownCircle size={70} color={themeContext.colors.homeSectionIconGoNextColor}/> */}
          </HashLink>
        </Content>
      </ContainerPadding>
    </Container>
      
    </Section>
}

export default Home;