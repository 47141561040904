import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Section, Container, ContainerPadding, Content, TitleContainer, Title, SubTitle, ContentTitle, ContentSubtextNegativeHighlighted } from "../section/basic";
import { AiOutlineProject, AiTwotoneExperiment } from "react-icons/ai";
import { MdGroups, MdDeveloperMode } from "react-icons/md"
import { ImDatabase } from "react-icons/im";
import { DeviceSize } from "../../utils/deviceSize";
import { hexToRGB, hexToHSL } from "../../utils/colorHelper";
import useOnScreen from "../../utils/useOnScreen";

const TECH_SKILLS = [
    {type: "C# .NET, SQL Server", level: 90},
    {type: "Flutter, Golang, PostgreSQL, Docker, Git", level: 85},
    {type: "React JS, HTML, CSS, Javascript, PHP, MySQL", level: 80},
];

const DESIGN_SKILLS = [
    {type: "GIMP", level: 90},
    {type: "Photoshop", level: 85},
];

const MANAGEMENT_SKILLS = [
    {type: "Visio", level: 90},
    {type: "Azure DevOps, Scrum", level: 85},
];

const Marginer = styled.div`
    height: 50px;;
`;

const DomainSkillsContainer = styled.div`
    width: 100%;    
    display: flex;
    flex-direction: column;
`;

const DomainSkillContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
`;

const TechnicalSkillsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SkillItems = styled.ul`
    margin: 0 0 30px 0;
    padding: 0;
`;

const SkillItemContainer = styled.li`
    width: 100%;
    display: block;
    position: relative;
    background-color: ${props => hexToRGB(props.theme.colors.dark, 0.4)};
    margin: 10px 0;
    
`;

const SkillLevel = styled.div`
    background-color: ${props => props.theme.colors.colored};
    transition: width 1000ms ease-in-out;
    height: 30px;
    .collapsed & {
      width: 0 !important;
    }
`;

const SkillTextContainer = styled.span`
    position: absolute;
    top: 4px;
    left: 15px;

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        top: 6px;
    }
`;



const HorizontalMarginer = styled.div`
    width: 20px;
`;

export function Skills(props) {
    const themeContext = useContext(ThemeContext);

    const ref = useRef(null);

    const [collapsed, setCollapsed] = useState(true);
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
    
    useEffect(() => {}, []);

    const isVisible = useOnScreen(ref);
    if(isVisible) {
        setTimeout(() => {
            setCollapsed(false);
        }, 300);
    }

    return <Section id="skills">
        <Container>
            <ContainerPadding>
                <TitleContainer>
                    <Title>Mes compétences</Title>
                    <SubTitle>Mon parcours professionnel m'a permis d'acquérir une autonomie "fullstack" que je mets aujourd'hui à votre service.</SubTitle>
                </TitleContainer>
                <Content className={collapsed ? "collapsed" : ""}>
                    <DomainSkillsContainer>
                        <DomainSkillContainer>
                            <AiOutlineProject size={isMobile ? 30 : 40 }/>
                            <HorizontalMarginer/>
                            Gestion de projet
                        </DomainSkillContainer>
                        <DomainSkillContainer>
                            <MdGroups size={isMobile ? 30 : 40 }/>
                            <HorizontalMarginer/>
                            Lead technique
                        </DomainSkillContainer>
                        <DomainSkillContainer>
                            <AiTwotoneExperiment size={isMobile ? 30 : 40 }/>
                            <HorizontalMarginer/>
                            Veille technologique
                        </DomainSkillContainer>
                        <DomainSkillContainer>
                            <MdDeveloperMode size={isMobile ? 30 : 40 }/>
                            <HorizontalMarginer/>
                            Développement "fullstack"
                        </DomainSkillContainer>
                        <DomainSkillContainer>
                            <ImDatabase size={isMobile ? 30 : 40 }/>
                            <HorizontalMarginer/>
                            Gestion de base de données
                        </DomainSkillContainer>
                    </DomainSkillsContainer>
                    <Marginer/>
                    <TechnicalSkillsContainer ref={ref}>
                        <ContentTitle>
                            Développement
                        </ContentTitle>
                        <SkillItems className="skills">
                            {TECH_SKILLS.map((skill, index) => 
                                <SkillItemContainer key={index}>
                                    <SkillLevel
                                        key={skill.type}
                                        style={{ width: `${skill.level}%`, backgroundColor: hexToHSL(themeContext.colors.colored, (100 / (index + 2))) }}>
                                    </SkillLevel>
                                    <SkillTextContainer>
                                        <ContentSubtextNegativeHighlighted>
                                            {skill.type}
                                        </ContentSubtextNegativeHighlighted>
                                    </SkillTextContainer>
                                </SkillItemContainer>
                            )} 
                        </SkillItems>
                        <ContentTitle>
                            Design
                        </ContentTitle>
                        <SkillItems className="skills">
                            {DESIGN_SKILLS.map((skill, index) => 
                                <SkillItemContainer key={index}>
                                    <SkillLevel
                                        key={skill.type}
                                        style={{ width: `${skill.level}%`, backgroundColor: hexToHSL(themeContext.colors.colored, (100 / (index + 2))) }}>
                                    </SkillLevel>
                                    <SkillTextContainer>
                                        <ContentSubtextNegativeHighlighted>
                                            {skill.type}
                                        </ContentSubtextNegativeHighlighted>
                                    </SkillTextContainer>
                                </SkillItemContainer>
                            )} 
                        </SkillItems>
                        <ContentTitle>
                            Gestion
                        </ContentTitle>
                        <SkillItems className="skills">
                            {MANAGEMENT_SKILLS.map((skill, index) => 
                                <SkillItemContainer key={index}>
                                    <SkillLevel
                                        key={skill.type}
                                        style={{ width: `${skill.level}%`, backgroundColor: hexToHSL(themeContext.colors.colored, (100 / (index + 2))) }}>
                                    </SkillLevel>
                                    <SkillTextContainer>
                                        <ContentSubtextNegativeHighlighted>
                                            {skill.type}
                                        </ContentSubtextNegativeHighlighted>
                                    </SkillTextContainer>
                                </SkillItemContainer>
                            )} 
                        </SkillItems>
                    </TechnicalSkillsContainer>
                </Content>
            </ContainerPadding>
        </Container>
    </Section>;
}

export default Skills;