import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { DeviceSize } from "../../utils/deviceSize";
import { ContentSubtitle, ContentTitle } from "../section/basic";
import LazyLoad from 'react-lazyload';

const Container = styled(motion.div)
.attrs(props => ({
    layout: true,
    initial: {opacity:0, scaleY: 0}, 
    animate: {opacity:1, scaleY:1},
    exit: {opacity:0, scaleY: 0},
    whileHover: { 
        scale: 1.1,
    }
}))`
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
    margin-left: 10px;
    margin-top: 10px;
    background-color: ${props => props.theme.colors.white};
    cursor: pointer;

    @media ${`(max-width: ${DeviceSize.mobile}px)`} {
        margin-left: 0;
        margin-top: 20px;
    }
`;

const ProjectImage = styled.img
    .attrs(props => ({
        src: props.url || "",
        alt: props.name || "",
    }))`
    width: 100%;
    height: 253px;
    object-fit: cover;
`;

const ProjectContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const VerticalMarginer = styled.div`
    height: 20px;
`;

export function Item({item, toggleModal}) {
    return (
        <Container onClick={() => toggleModal(item)}>
            <LazyLoad height={253} once>
                <ProjectImage url={item.image} name={item.name} />
            </LazyLoad>
            <ProjectContent>
                <ContentTitle>{item.name}</ContentTitle>
                <ContentSubtitle>{item.sub_category}</ContentSubtitle>
                <VerticalMarginer/>
                {item.description}
            </ProjectContent>
        </Container>
    );
}

export default Item;