import axios from "axios";

export function sendMail(data, callback) {
    axios.post("https://www.david-maria.fr/server/api/contact/index.php", data)
        .then(() => {
            callback("Votre message a été envoyé");
        })
        .catch(() => {
            callback("Une erreur est survenue, veuillez ré-essayer plus tard");
        });
}