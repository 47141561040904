import styled from "styled-components";
import { DeviceSize } from "../../../utils/deviceSize";

// OTHER SECTION
export const Section = styled.section`
    width: 100%;
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ bgcolor }) => bgcolor || (props => props.theme.colors.light)};
    color: ${props => props.theme.colors.dark};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: ${props => props.theme.sizes.navBarHeight};

    @media ${`(min-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentDesktopWidth};
    }

    @media ${`(min-width: ${DeviceSize.tablet}px) and (max-width: ${DeviceSize.laptop}px)`} {
        width: ${props => props.theme.sizes.sectionContentLaptopWidth};
    }

    @media ${`(min-width: ${DeviceSize.mobile}px) and (max-width: ${DeviceSize.tablet}px)`} {
        width: ${props => props.theme.sizes.sectionContentTabletWidth};
    }
`;

export const ContainerPadding = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

export const TitleContainer = styled.div`
    margin-top: ${props => props.theme.margins.sectionTitleMarginTop};
`;

export const Title = styled.h1`
    margin: 0;
    color: ${({ color }) => color || (props => props.theme.colors.dark)};
    font-size: ${props => props.theme.fontSizes.title};
    letter-spacing: ${props => props.theme.letterSpacings.title};
    font-weight: bold;
    text-transform: uppercase;
`;

export const SubTitle = styled.h2`
    color: ${({ color }) => color || (props => props.theme.colors.colored)};
    font-size: ${props => props.theme.fontSizes.subTitle};
    font-weight: 600;
    margin: 0;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: ${props => props.theme.margins.sectionContentMarginTop};
    margin-bottom: ${props => props.theme.margins.sectionContentMarginBottom};

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        flex-direction: column;
    }
`;

export const ContentTitle = styled.div`
    font-size: ${props => props.theme.fontSizes.contentTitle};
    font-weight: 700;
    text-transform: uppercase;
`;

export const ContentSubtitle = styled.div`
    font-size: ${props => props.theme.fontSizes.contentSubtitle};
    color: ${props => props.theme.colors.colored};
    font-weight: 600;
    text-transform: uppercase;
`;

export const ContentSubtextNegativeHighlighted = styled.div`
    font-size: ${props => props.theme.fontSizes.contentSubtext};
    color: ${props => props.theme.colors.light};
    font-weight: 700;
`;

export const ContentSubtextNegative = styled.div`
    font-size: ${props => props.theme.fontSizes.contentSubtext};
    color: ${props => props.theme.colors.light};
`;

export const BannerContainer = styled.div`
    width: 100%;
`;

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 70px 70px;
    overflow: hidden;
`;