import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const Button = styled.button`
    padding: 8px 14px;
    background: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.colored};
    border-radius: 16px;
    cursor: pointer;
    margin-right: 10px;
    border: ${props => props.theme.colors.dark} solid 1px;

    &.active {
        background: ${props => props.theme.colors.colored};
        color: ${props => props.theme.colors.light};
    }
`;

export function Filters({items, activeFilter, setFilters, setActiveFilter}) {
    useEffect(() => {
        if(activeFilter === "all") {
            setFilters(items);
            return;
        }
        const filteredItem = items.filter(i => i.category.includes(activeFilter));
        setFilters(filteredItem);

    }, [activeFilter, items, setFilters]);

    return (
        <Container>
            <Button 
                onClick={() => setActiveFilter("all")} 
                className={activeFilter === "all" && "active"}
            >
                Tous
            </Button>
            <Button 
                onClick={() => setActiveFilter("web")} 
                className={activeFilter === "web" && "active"}
            >
                Web
            </Button>
            <Button 
                onClick={() => setActiveFilter("mobile")} 
                className={activeFilter === "mobile" && "active"}
            >
                Mobile
            </Button>
            <Button 
                onClick={() => setActiveFilter("pro")} 
                className={activeFilter === "pro" && "active"}
            >
                Progiciel
            </Button>
        </Container>
    );
}

export default Filters;