export function getData(callback)  {
    fetch('data/site.json'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
    .then(response => response.json())
    .then(data => callback(data));
}