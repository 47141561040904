import React from "react";
import styled from "styled-components";
import About from "../components/main/about";
import Home from "../components/main/home";
import Contact from "../components/main/contact";
import Projects from "../components/main/projects";
import Services from "../components/main/services";
import Skills from "../components/main/skills";
import ServicesBanner from "../components/main/servicesBanner";
import SkillsBanner from "../components/main/skillsBanner";
import ProjectsBanner from "../components/main/projectsBanner";
import AboutBanner from "../components/main/aboutBanner";
import Infos from "../components/main/infos";

const Container = styled.div`
  width: 100%;
`;

const MainPage = () => {
  return (
    <Container>
      <Home/>
      <Services/>
      <ServicesBanner/>
      <Skills/>
      <SkillsBanner/>
      <Projects/>
      <ProjectsBanner/>
      <About/>
      <AboutBanner/>
      <Contact/>
      <Infos/>
    </Container>
  );
}

export default MainPage;