import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { DeviceSize } from "../../utils/deviceSize";
import { motion } from "framer-motion";

const Background = styled(motion.div)
  .attrs({
      initial: { y: "100vh" },
      animate: { y: 0 },
      exit: { y: "100vh" },
      transition: { type: "tween" }
  })`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
  background-color: #fff;
  position: relative;
  overflow: auto;

  @media ${`(min-width: ${DeviceSize.laptop}px)`} {
      width: ${props => props.theme.sizes.sectionContentDesktopWidth};
  }

  @media ${`(min-width: ${DeviceSize.tablet}px) and (max-width: ${DeviceSize.laptop}px)`} {
      width: ${props => props.theme.sizes.sectionContentLaptopWidth};
  }

  @media ${`(min-width: ${DeviceSize.mobile}px) and (max-width: ${DeviceSize.tablet}px)`} {
      width: ${props => props.theme.sizes.sectionContentTabletWidth};
  }
`;

export const ContainerPadding = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const CloseBtnStyle = styled.span`
  margin-top: 10px;
  top: ${props => props.theme.sizes.navBarHeight};
  right: 10px;
  position: fixed;
  cursor: pointer;
  border: 2px solid ${props => props.theme.colors.light};
  background-color: ${props => props.theme.colors.white};
  padding: 7px 18px;

  &:hover {
    background-color: ${props => props.theme.colors.colored};
    color: ${props => props.theme.colors.light};
  }
`;

const Content = styled.div`
  margin-top: ${props => props.theme.sizes.navBarHeight};
`;

export function Modal(props) {
  // const controls = useAnimation();

  // if (!props.isOpen) {
  //   controls.start("hidden");
  //   document.body.style.overflow = 'visible';
  // } else {
  //   controls.start("visible");
  //   document.body.style.overflow = 'hidden';
  // }
  document.body.style.overflow = 'hidden';

  const close = () => {
    document.body.style.overflow = 'visible';
    props.onClose();
  }

  return (
    <Background id="ModalBack">
      <Container>
        <ContainerPadding>
          <Header>
            <div>&nbsp;</div>
            <CloseBtnStyle onClick={close}>
              <AiOutlineClose size={15}/>
            </CloseBtnStyle>
            </Header>
            <Content>
              {props.children}
            </Content>
        </ContainerPadding>
      </Container>
    </Background>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node
};

export default Modal;
