import React from "react";
import styled from "styled-components";
import { AiFillCloud } from "react-icons/ai";
import { GoDeviceMobile, GoDeviceDesktop } from "react-icons/go";
import { DeviceSize } from "../../utils/deviceSize";
import { Section, Container, ContainerPadding, Content, TitleContainer, Title, SubTitle, ContentTitle } from "../section/basic";
import LazyLoad from 'react-lazyload';
import HomeImg from "../../assets/images/services.jpg"

const Service = styled.div`
    width: 100%;
    height: 300px;
    margin-right: 20px;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;

    &:hover .avatar {
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.colored};
    }

    &:hover .servicebg {
        transform: scale(2);
        opacity: 0.2;
    }

    &:focus .servicebg {
        transform: scale(2);
        opacity: 0.2;
    }

    @media ${`(max-width: ${DeviceSize.tablet}px)`} {
        margin: 0;    
        margin-bottom: 20px;
        flex-direction: column;
        align-items: center;

        & .servicebg {       
            transform:scale(2);
            opacity: 0.2;
        }
    }
`;

const ServiceWebImg = styled.img
    .attrs(props => ({
        src: `${HomeImg}`,
        alt: "Site web",
    }))`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: 280px 180px;
    transform:scale(1);
    transition: all 0.3s ease-in;
    opacity: 0;
`;

const ServiceBackground = styled.div`
    position: absolute;
    top: 0;
    height: 400px;
    left: 0;

    & > .lazyload-wrapper  {
        height: 400px;
    }
`;

const ServiceProImg = styled.img
    .attrs(props => ({
        src: `${HomeImg}`,
        alt: "Progiciel",
    }))`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: right 20px;
    transform:scale(2);
    opacity: 0.2;
`;

const ServiceMobileImg = styled.img
    .attrs(props => ({
        src: `${HomeImg}`,
        alt: "Application mobile",
    }))`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: left 160px;
    transform:scale(1);
    transition: all 0.3s ease-in;
    opacity: 0;
`;


const ServiceAvatar = styled.div
    .attrs({
        className: `avatar`
    })`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    background-color: ${props => props.theme.colors.colored};
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    border: 2px solid ${props => props.theme.colors.colored};
    z-index: 0;
`;

const Marginer = styled.div`
    height: 40px;
`;

export function Services(props) {
    return <Section id="services">
        <Container>
            <ContainerPadding>
                <TitleContainer>
                    <Title>Mes services</Title>
                    <SubTitle>Des prestations adaptées à vos besoins</SubTitle>
                </TitleContainer>
                <Content>
                    <Service>
                        <ServiceBackground>
                            <LazyLoad height={400} once>
                                <ServiceWebImg className="servicebg"/>
                            </LazyLoad>
                        </ServiceBackground>
                        <ServiceAvatar>
                            <AiFillCloud size={50} />
                        </ServiceAvatar>
                        <Marginer/>
                        <ContentTitle>
                            Web
                        </ContentTitle>
                        <Marginer/>
                        Site vitrine, portfolio, corporate, évènementiel, intranet.
                    </Service>
                    <Service>
                        <ServiceBackground>
                            <LazyLoad height={400} once>
                                <ServiceProImg/>
                            </LazyLoad>
                        </ServiceBackground>
                        <ServiceAvatar>
                            <GoDeviceDesktop size={50} />
                        </ServiceAvatar>
                        <Marginer/>
                        <ContentTitle>
                            Progiciel
                        </ContentTitle>
                        <Marginer/>
                        Des solutions sur mesure adaptées à votre coeur de métier.
                    </Service>
                    <Service>
                        <ServiceBackground>
                            <LazyLoad height={400} once>
                                <ServiceMobileImg className="servicebg"/>
                            </LazyLoad>
                        </ServiceBackground>
                        <ServiceAvatar>
                            <GoDeviceMobile size={50} />
                        </ServiceAvatar>
                        <Marginer/>
                        <ContentTitle>
                            Mobile
                        </ContentTitle>
                        <Marginer/>
                        Application IOS/Android.
                    </Service>
                </Content>
            </ContainerPadding>
        </Container>
    </Section>;
}

export default Services;