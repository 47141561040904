import React from "react";
import styled from "styled-components";
import LogoImg from "../../assets/images/logo.png"

const LogoContainer = styled.div`
    height: ${props => props.theme.sizes.logoHeight};
    width: ${props => props.theme.sizes.logoWidth};
`;

const LogoImage = styled.img
.attrs({
    src: `${LogoImg}`,
    alt: "david-maria"
})`
    width: 100%;
    height: 100%;
    -webkit-filter: grayscale(1) invert(1);
      filter: grayscale(1) invert(1);

    &.sticky {
      -webkit-filter: grayscale(1) invert(1);
      filter: grayscale(1) invert(1);
    }
`;

export function Logo(props) {
    return <LogoContainer>
        <LogoImage className={props.className}/>
    </LogoContainer>
}

export default Logo;